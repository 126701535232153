import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createAction } from '@wix/communities-blog-client-common';
import { isMemberAreaInstalled } from '../communities-context/communities-context-actions';
import { PARAM_MEMBER_ID } from './constants';
import { sectionUrlToLink } from './section-url-to-link';

export const SET_MA_NAVIGATION = 'membersAreaNavigation/SET';
export const setMaNavigation = createAction(SET_MA_NAVIGATION);

const SETTINGS_SECTION_APP_ID = '14f25dc5-6af3-5420-9568-f9c5ed98c9b1';
const NOTIFICATIONS_SECTION_APP_ID = '14f25924-5664-31b2-9568-f9c5ed98c9b1';

const SETTINGS_SECTION_WIDGET_PLUGIN_ID =
  '14f25dd2-f9b0-edc2-f38e-eded5da094aa';
const NOTIFICATIONS_SECTION_WIDGET_PLUGIN_ID =
  '14f2595a-a352-3ff1-9b3c-4d21861fe58f';

const settingsLink = {
  appDefinitionId: SETTINGS_SECTION_APP_ID,
  sectionId: 'settings',
  widgetId: SETTINGS_SECTION_WIDGET_PLUGIN_ID,
};

const notificationsLink = {
  appDefinitionId: NOTIFICATIONS_SECTION_APP_ID,
  sectionId: 'notifications_app',
  widgetId: NOTIFICATIONS_SECTION_WIDGET_PLUGIN_ID,
};

const initializeMemberAreaNavigation =
  () =>
  async (dispatch, getState, { wixCodeApi }) => {
    let navigation;
    try {
      const api = await wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);
      if (!api) {
        return;
      }

      navigation = await Promise.all([
        api.getMemberPagePrefix({ type: 'public' }),
        api.getSectionUrl({ ...settingsLink, memberId: PARAM_MEMBER_ID }),
        api.getSectionUrl({ ...notificationsLink, memberId: PARAM_MEMBER_ID }),
        api.getNavigatableRoles(),
      ]);
    } catch (_) {
      return;
    }

    const [
      prefix,
      settingsSectionUrl,
      notificationsSectionUrl,
      navigatableRoles,
    ] = navigation;
    await dispatch(
      setMaNavigation({
        profileLinkTemplate: `/${prefix.prefix}/${PARAM_MEMBER_ID}`,
        settingsLink: sectionUrlToLink(wixCodeApi.location, settingsSectionUrl),
        notificationsLink: sectionUrlToLink(
          wixCodeApi.location,
          notificationsSectionUrl,
        ),
        isNavigationAllowed: navigatableRoles.isNavigationAllowed,
      }),
    );
  };

export const initializeMembersNavigation =
  () =>
  async (dispatch, getState, { wixCodeApi }) => {
    if (await isMemberAreaInstalled(wixCodeApi)) {
      return dispatch(initializeMemberAreaNavigation());
    }
  };
