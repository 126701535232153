import { get, nthArg } from 'lodash';

import {
  resolveId,
  createAction,
  urijs,
  getCategoryIds,
} from '@wix/communities-blog-client-common';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getDemoPosts } from '../../common/services/demo-posts';
import getEnvironment from '../../common/services/get-environment';
import { normalizePosts } from '../../common/services/post-utils';

export const FETCH_RECENT_POSTS_REQUEST = 'recentPosts/FETCH_REQUEST';
export const FETCH_RECENT_POSTS_SUCCESS = 'recentPosts/FETCH_SUCCESS';
export const FETCH_RECENT_POSTS_FAILURE = 'recentPosts/FETCH_FAILURE';

export const fetchRecentPostsRequest = createAction(FETCH_RECENT_POSTS_REQUEST);
export const fetchRecentPostsSuccess = createAction(
  FETCH_RECENT_POSTS_SUCCESS,
  nthArg(0),
  nthArg(1),
);
export const fetchRecentPostsFailure = createAction(
  FETCH_RECENT_POSTS_FAILURE,
  nthArg(0),
  nthArg(1),
);

export const POST_COUNT = 3;

export const getQuery = (post, lang, postCount = POST_COUNT) =>
  urijs('/_api/posts').query({
    categoryIds: get(post, 'categoryIds', []).join(','),
    excludeIds: resolveId(post),
    size: postCount,
    offset: 0,
    ...(lang && { lang }),
  });

export default function fetchRecentPosts(post, postCount = POST_COUNT) {
  return (dispatch, getState, { request, httpClient, wixCodeApi }) => {
    const params = { postId: resolveId(post) };
    dispatch(fetchRecentPostsRequest(params));
    const lang = getQueryLocale(getState());
    const promise = request(getQuery(post, lang, postCount));

    // prettier-ignore
    return promise
      .then(async (posts) => {
        if (!posts.length && getEnvironment(wixCodeApi).isEditorSegment) {
          const fake = await getDemoPosts({ httpClient, getState, dispatch, wixCodeApi, query: { pageSize: postCount + 1, excludeIds: [post.id] } });
          posts = fake.posts;
        }
        const normalizedPosts = normalizePosts({
          state: getState(),
          posts,
          blogCategoryIds: getCategoryIds(getState()),
        });

        dispatch(fetchRecentPostsSuccess(normalizedPosts, params));
      })
      .catch(() => dispatch(fetchRecentPostsFailure(undefined, params)))
      .then(() => promise);
  };
}
