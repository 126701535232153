import type React from 'react';
import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { joinUrlPaths } from '@wix/communities-blog-client-common';
import { BLOG_FEED_PATH } from '@wix/communities-blog-universal/dist/src/constants/app';
import { createLogger } from '../../external/common/controller/helpers';
import getEnvironment from '../../external/common/services/get-environment';
import type Widget from './Widget';

const createController: CreateControllerFn = async ({
  controllerConfig,
}: ControllerParams) => {
  const { isDebug, isProduction } = getEnvironment(controllerConfig.wixCodeApi);
  const log = createLogger(isDebug, isProduction);
  log('createRssButtonController', controllerConfig);

  return {
    pageReady() {
      log('createRssButtonController.pageReady -> start');

      setProps({
        rssFeedLink: getRssFeedLink(),
      });

      log('createRssButtonController.pageReady -> done');
    },
  };

  function getRssFeedLink() {
    /** "" if site is not published */
    const baseUrl = controllerConfig.wixCodeApi.location.baseUrl;

    return baseUrl ? joinUrlPaths(baseUrl, [BLOG_FEED_PATH]) : undefined;
  }

  /** Type-safe wrapper */
  function setProps(props: React.ComponentProps<typeof Widget>) {
    controllerConfig.setProps(props);
  }
};

export default createController;
