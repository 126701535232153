import { usersPageOpenedEvent } from '../../common/bi-events/users-events/users-page-opened-event';
import { usersOpenPostPageEditorEvent } from './users-open-post-page-editor-event';
import { usersPostPageResizeEvent } from './users-post-page-resize-event';
import { usersSetViewModeEvent } from './users-set-view-mode-event';

export const usersEventMap = {
  ...usersSetViewModeEvent,
  ...usersPostPageResizeEvent,
  ...usersPageOpenedEvent,
  ...usersOpenPostPageEditorEvent,
};
