import { get } from 'lodash';
import { Dispatch } from 'redux';
import { IWixAPI } from '@wix/yoshi-flow-editor';
import {
  createAction,
  GLOBALS,
  urijs,
} from '@wix/communities-blog-client-common';
import { getInstance } from '../../controller/helpers';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { resolveDeviceType } from '../../services/device-type';
import getEnvironment from '../../services/get-environment';
import { isRtlLanguage } from '../../services/is-rtl-language';
import { GetState } from '../../types';

export const SET_BASIC_PARAMS = 'SET_BASIC_PARAMS';
export const setBasicParamsAction = createAction(SET_BASIC_PARAMS);

export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const setViewMode = createAction(SET_VIEW_MODE);

export const SET_INSTANCE = 'SET_INSTANCE';
export const setInstance = createAction(SET_INSTANCE);

interface SetBasicParamsParams {
  viewMode: string;
  language: string;
  biPageNumber?: number;
  isRendered?: boolean;
}

export const setBasicParams =
  ({ viewMode, language, biPageNumber, isRendered }: SetBasicParamsParams) =>
  async (
    dispatch: Dispatch,
    getState: GetState,
    { wixCodeApi }: { wixCodeApi: IWixAPI },
  ) =>
    dispatch(
      setBasicParamsAction(
        await resolveBasicParams({
          viewMode,
          language,
          biPageNumber,
          wixCodeApi,
          getState,
          isRendered,
        }),
      ),
    );

interface ResolveBasicParamsParams {
  viewMode: string;
  language: string;
  biPageNumber?: number;
  wixCodeApi: IWixAPI;
  getState: GetState;
  isRendered?: boolean;
}

export const resolveBasicParams = async ({
  viewMode,
  language,
  biPageNumber,
  wixCodeApi,
  getState,
  isRendered,
}: ResolveBasicParamsParams) => {
  const showMobileView =
    wixCodeApi.location.url &&
    urijs(wixCodeApi.location.url).hasQuery('showMobileView', 'true');
  const isCreatedWithResponsiveEditor = getIsCreatedWithResponsiveEditor(
    getState(),
  );
  const { isSSR, isDebug } = getEnvironment(wixCodeApi);
  const isRTL = isRtlLanguage(language);
  const currentPage = wixCodeApi.site.currentPage;
  const apiBaseUrl = isSSR ? GLOBALS.API_BASE_URL : GLOBALS.API_BASE_URL_CLIENT;

  return {
    viewMode,
    instance: getInstance(wixCodeApi)(),
    formFactor: showMobileView
      ? 'Mobile'
      : resolveDeviceType(wixCodeApi, isCreatedWithResponsiveEditor),
    isSSR,
    isRTL,
    isSeo: wixCodeApi.seo.isInSEO(),
    language,
    apiBaseUrl,
    isDebug,
    isProduction: process.env.NODE_ENV === 'production',
    biPageNumber,
    page: wixCodeApi.location.query.page,
    commentId: get(wixCodeApi, 'location.query.commentId'),
    url: wixCodeApi.location.url,
    currentPage,
    timeZone: get(wixCodeApi, 'site.timezone'),
    isRendered,
  };
};
