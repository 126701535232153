import { VIEW_MODE_EDITOR } from '@wix/communities-blog-client-common';
import { SET_VIEW_MODE } from '../../common/store/basic-params/basic-params-actions';
import { getViewMode } from '../../common/store/basic-params/basic-params-selectors';

const handler = (action, state) => {
  const lastViewMode = getViewMode(state);
  const nextViewMode = action.payload;

  if (nextViewMode !== VIEW_MODE_EDITOR) {
    return;
  }

  return {
    evid: 350,
    origin: lastViewMode || nextViewMode,
    eventMeta: {
      description: 'post page - post page opened',
    },
  };
};

export const usersSetViewModeEvent = {
  [SET_VIEW_MODE]: handler,
};
