import { compact } from 'lodash';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {
  FETCH_BLOG_DATA_SUCCESS,
  GLOBALS,
  SET_APP_CONFIG,
} from '@wix/communities-blog-client-common';
import {
  appUouBiMiddleware,
  appUsersBiMiddleware,
} from '../../common/bi-events/init-middleware';
import { createFastFormSubmitMiddleware } from '../../common/components/fast-form/store/fast-form-submit-middleware';
import { getInstance } from '../../common/controller/helpers';
import createShowMessage from '../../common/messages/framework/create-show-message';
import { createMessageMiddleware } from '../../common/messages/framework/initialize-store';
import {
  createDuplexerMiddleware,
  socketEventHandlers,
} from '../../common/middleware/duplexer-middleware';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import {
  debounceActions,
  throttleActions,
} from '../../common/middleware/redux-middlewares';

import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
} from '../../common/modals/framework/initialize-store';
import createRequests from '../../common/services/create-requests';
import createStore from '../../common/services/create-store';
import { SET_APP_SETTINGS } from '../../common/store/app-settings/app-settings-actions';
import { SET_BASIC_PARAMS } from '../../common/store/basic-params/basic-params-actions';
import { FETCH_CATEGORIES_SUCCESS } from '../../common/store/categories/fetch-categories';
import { SET_INSTANCE_VALUES } from '../../common/store/instance-values/instance-values-actions';
import loadingBarMiddleware from '../../common/store/loading-bar/loading-bar-middleware';
import { FETCH_SITE_PROPERTIES_SUCCESS } from '../../common/store/site-properties/site-properties-types';
import { FETCH_TOPOLOGY_SUCCESS } from '../../common/store/topology/topology-actions';
import { waitForActionMiddleware } from '../../common/store/wait-for-action/wait-for-action-middleware';
import { eventMap } from '../bi-events/uou-events';
import { usersEventMap } from '../bi-events/users-events';
import messageResolvers from '../components/messages/message-resolvers';
import { modalDataLoaderByType } from '../components/modals/modal-data-loader-map-by-type';
import { modalResolverMapByType } from '../components/modals/modal-resolver-map-by-type';

export function createReduxStore({
  reducers,
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  platformAPIs,
  getRouter,
  isSSR,
  sentry,
  isEditor,
  isPreview,
  language,
  bundleName,
  flowAPI,
}) {
  const p = {};

  const { request, platformizedRequest, paywallRequest, blogApiBaseUrl } =
    createRequests({
      wixCodeApi,
      bundleName,
      getStore: () => p.store,
      flowAPI,
    });

  const isSeo = wixCodeApi.seo.isInSEO();

  const disableDuplexer = (GLOBALS.DISABLE_DUPLEXER_FOR_INSTANCE_IDS || '')
    .split(',')
    .includes(appParams.instanceId);
  const useDuplexer =
    !isSSR && !isEditor && !isPreview && !isSeo && !disableDuplexer;

  const middleware = compact([
    throttleActions(),
    debounceActions(),
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      paywallRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      getRouter,
      httpClient: flowAPI.httpClient,
    }),
    waitForActionMiddleware([
      SET_APP_SETTINGS,
      SET_APP_CONFIG,
      SET_INSTANCE_VALUES,
      SET_BASIC_PARAMS,
      FETCH_BLOG_DATA_SUCCESS,
      FETCH_SITE_PROPERTIES_SUCCESS,
      FETCH_TOPOLOGY_SUCCESS,
      FETCH_CATEGORIES_SUCCESS,
    ]),
    createFastFormSubmitMiddleware({}),
    // createRouterMiddleware(getRouter),
    createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
    createModalOpenedMiddleware({ modalDataLoaderByType }),
    createMessageMiddleware({ resolvers: messageResolvers }),
    postMetadataMiddleware({ isSSR, language }),
    // createInteractionsMiddleware(fedopsLogger, {
    //   [CREATE_POST_SUCCESS]: CREATE_POST,
    //   [UPDATE_POST_SUCCESS]: UPDATE_POST,
    //   [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
    //   [DELETE_POST_SUCCESS]: DELETE_POST,
    //   [CREATE_COMMENT_SUCCESS]: CREATE_COMMENT,
    //   [UPDATE_COMMENT_SUCCESS]: UPDATE_COMMENT,
    //   [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: LIKE_COMMENT,
    //   [DELETE_COMMENT_SUCCESS]: DELETE_COMMENT,
    // }),
    isEditor
      ? appUsersBiMiddleware({
          platformAPIs,
          instanceId: appParams.instanceId,
          eventMap: usersEventMap,
        })
      : appUouBiMiddleware({
          platformAPIs,
          instanceId: appParams.instanceId,
          eventMap,
        }),
    loadingBarMiddleware(),
    useDuplexer &&
      createDuplexerMiddleware({
        getInstance: getInstance(wixCodeApi),
        duplexerUrl: GLOBALS.DUPLEXER_URL,
        blogApiBaseUrl,
        eventHandlers: socketEventHandlers,
        instanceId: appParams.instanceId,
        captureMessage: (message, extra) =>
          sentry.captureException(message, { extra }),
      }),
  ]);

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
