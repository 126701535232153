import { ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';

interface FetchMetadataParams {
  flowAPI: ViewerScriptFlowAPI;
  baseURL: string;
  postId: string;
}

interface MetadataResponse {
  viewCount: number;
  likeCount: number;
  totalComments: number;
  isLiked: boolean;
}

export const fetchMetadata = async ({
  flowAPI,
  baseURL,
  postId,
}: FetchMetadataParams) => {
  const { data: metadata } = await flowAPI.httpClient.get<MetadataResponse>(
    `_api/communities-blog-node-api/_api/posts/metadata/${postId}`,
    {
      baseURL,
    },
  );
  return metadata;
};
