import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = (({ $bind, $widget, flowAPI, $w }) => {
  const blogFacade = new BlocksWidgetFacade(flowAPI);
  const ui = {
    tags: $w('#tagsElement') as $w.SelectionTags,
  } as const;

  const setTags = (tags: BlogTag[]) => {
    if (tags.length) {
      ui.tags.options = tags.map((tag) => ({
        label: tag.label ?? '',
        value: tag.slug ?? '',
      }));
    } else {
      ui.tags.delete();
    }
  };

  const handleTagClick = async (event: $w.Event) => {
    const tagsClicked = event.target.value;
    const tagSlug = tagsClicked.at(-1);
    if (!tagSlug) {
      throw new Error('No tag clicked');
    }
    await blogFacade.navigateToTagFeed(tagSlug);
  };

  return {
    pageReady: async () => {},
    exports: {
      initialize: (renderModel: PostPageRenderModel) => {
        setTags(renderModel.tags);
        /** change to onClick when issue with first onClick call will be resolved.*/
        ui.tags.onChange(handleTagClick);
      },
    },
  };
}) satisfies CreateController;

export type PostTagsApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
