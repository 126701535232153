import { AppData } from '../../../../viewer.app';
import { completeFetchPost } from '../../../common/actions/fetch-post';
import { AppStore, RouteResolverFn, WixCodeApi } from '../../../common/types';
import { setReadingSessionId } from '../../actions/reading-session-id-actions';
import { postPageRouterPostHandler } from './post-page-router-post-handler';

interface CreatePostPageRouterParams {
  appData: AppData;
  store: AppStore;
  wixCodeApi: WixCodeApi;
}

export const createSplitPostPageRouter =
  ({
    appData,
    store,
    wixCodeApi,
  }: CreatePostPageRouterParams): RouteResolverFn =>
  async (_, redirect) => {
    const { post } = await appData.fetchPostPageRenderModel();
    const readingSessionId = `${parseInt(
      `${Math.random() * 10000000}`,
      10,
    )}-${Date.now()}`;

    store.dispatch(setReadingSessionId(readingSessionId));
    store.dispatch(completeFetchPost(post.slug, post));

    return postPageRouterPostHandler({
      post,
      store,
      appData,
      wixCodeApi,
      redirect,
    });
  };
