import type { NormalizedCategory } from '@wix/communities-blog-client-common';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

interface Option {
  label: string;
  value: string;
}

const createController = (({ $widget, $w, flowAPI }) => {
  const blogFacade = new BlocksWidgetFacade(flowAPI);
  let categories: Option[] = [];

  const ui = {
    categories: $w('#categoriesElement') as $w.SelectionTags,
  };

  const applyCategoriesState = () => {
    const options =
      $widget.props.categoriesToDisplay === 'mainCategory'
        ? [categories[0]]
        : categories;

    if (options.length) {
      ui.categories.options = categories.length ? options : [];
    } else {
      ui.categories.delete();
    }
  };

  const setCategories = (normalizedCategories: NormalizedCategory[]) => {
    categories = normalizedCategories.map((cat) => ({
      label: cat?.menuLabel,
      value: cat?.slug as string,
    }));
    applyCategoriesState();
  };

  const onCategoryClick = (callback: (slug: string) => Promise<void>) => {
    /** change to onClick when issue with first onClick call will be resolved.*/
    ui.categories.onChange(async (event: $w.Event) => {
      const categoriesClicked = event.target.value;
      const lastCategoryClicked = categoriesClicked.at(-1);
      if (!lastCategoryClicked) {
        throw new Error('No categories clicked');
      }

      callback(lastCategoryClicked);
    });
  };

  return {
    pageReady: async () => $widget.onPropsChanged(applyCategoriesState),
    exports: {
      initialize: (renderModel: PostPageRenderModel) => {
        setCategories(renderModel.categories);
        onCategoryClick((slug) => blogFacade.navigateToCategoryFeed(slug));
      },
    },
  };
}) satisfies CreateController;

export type PostCategoriesApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
